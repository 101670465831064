import CardPage from "./CardPage";

export default function HomePage() {
    return (
        <div>
            <CardPage />
        </div>
    );
}

